<template>
  <div>
    <Header ref="header"/>

    <div class="w w1390">
      <div class="crumbs-nav">
        <div class="crumbs-nav-main clearfix">
          <span>
            <router-link :to="{path:'/'}">首页</router-link>
          </span>
          <span class="arrow">&gt;</span>
          <span class="finish" v-if="keywords">搜索商品_{{keywords}}</span>
          <span class="finish" v-if="special">搜索商品_{{special}}专区</span>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="w w1390">
        <div class="filter">
          <div class="filter-wrap">
            <div class="filter-sort">
              <a href="javascript:void(0);" v-on:click="orderPrice">
                价格<i class="iconfont" :class="priceIcon"></i>
              </a>
            </div>
            <div class="filter-range">

            </div>
            <div class="filter-right">
              <div class="button-page">
                <span class="pageState"><span>{{paging.page}}</span>/{{ paging.totalPages }}</span>
                <a href="javascript:void(0);" title="上一页" v-on:click="handlePreChange"><i class="iconfont icon-left"></i></a>
                <a href="javascript:void(0);" title="下一页" v-on:click="handleNextChange"><i class="iconfont icon-right"></i></a>
              </div>

              <div class="styles">
                <ul class="items">
                  <li :class="['item',{'current':listType}]" v-on:click="listType = !listType" v-show="listType">
                    <a href="javascript:void(0)" title="大图模式">
                      <span class="iconfont icon-switch-grid"></span>大图
                    </a>
                  </li>
                  <li :class="['item',{'current':!listType}]" v-on:click="listType = !listType" v-show="!listType">
                    <a href="javascript:void(0)" title="小图模式">
                      <span class="iconfont icon-switch-list"></span>小图
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="g-view w">
          <div class="goods-list" v-loading="SearchLoading" element-loading-text="拼命加载中......">
            <div class="tip">
              <p>温馨提示：部分商品包装更换频繁，如货品与图片不完全一致，请以收到的商品实物为准。</p>
            </div>
            <div class="gl-warp gl-warp-large" :style="{display:listType?'block':'none'}" v-if="paging.list && paging.list.length > 0">
              <div class="goods-list-warp" v-if="carts.length>0">
                <ul>
                  <li class="gl-item" v-for="(good,index) in paging.list" v-bind:key="good.id">
                    <div class="gl-i-wrap">
                      <div class="p-img">
                        <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                          <img :src="getCoverImage(good.images)">
                        </router-link>
                      </div>
                      <div class="p-lie">
                        <div class="p-price">
                          <em>¥</em>{{good.price}}
                        </div>
                        <div class="original-price">
                          <em>¥</em>{{good.marketPrice}}
                        </div>
                      </div>
                      <div class="p-name">
                        <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                          {{good.name}}<template v-if="good.brandName!=''">({{good.brandName}})</template>
                        </router-link>
                      </div>
                      <div class="sepc">
                        <p v-for="(para,index) in good.paras" v-bind:key="index">{{para.name}}：{{para.value}}</p>
                      </div>
                      <div class="brief" v-if="good.subName">
                        [促]
                        <p v-for='(b,i) in good.subName.split("$$")' v-bind:key="i">
                          <template>{{b}}</template>
                        </p>
                      </div>
                      <div class="p-operate">
                        <amount-box v-model="carts[index].num" :defaultVal="good.num" :max="99999" :min="0"></amount-box>
                        <a href="javascript:void(0);" v-on:click="addCart(index,good)" class="addcart">
                          <i class="iconfont icon-carts"></i>加入购物车
                        </a>
                        <div style="clear: both"/>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="gl-warp gl-warp-samll" :style="{display:!listType?'block':'none'}" v-if="paging.list && paging.list.length > 0">
              <div class="goods-list-warp" v-if="carts.length>0">
                <ul>
                  <li :class="[index%2==0?'gl-h-item':'gl-h-item item_bg']" class="" v-for="(good,index) in paging.list" v-bind:key="good.id">
                    <div class="gl-i-wrap">
                      <div class="col col-1">
                        <div class="p-img">
                          <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank"  tag="a">
<!--                            <img :src="JSON.parse(good.images)[0].imageUrl">-->


                            <img :src="getCoverImage(good.images)" width="70" height="70">
                          </router-link>
                        </div>
                        <div class="p-right">
                          <div class="p-name">
                            <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                              {{good.name}}<template v-if="good.brandName!=''">({{good.brandName}})</template>
                            </router-link>
                          </div>
                        </div>
                      </div>
                      <div class="col col-2">
                        <div class="p-lie">
                          <ul>
                            <li v-for="(para,index) in good.paras" v-bind:key="index">{{para.name}}：{{para.value}}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col col-3">
                        <div class="p-price">
                          <div class="shop-price">
                            <em>¥</em>{{good.price}}
                          </div>
                          <div class="original-price">
                            <em>¥</em>{{good.marketPrice}}
                          </div>
                        </div>
                      </div>
                      <div class="col col-4">
                        <div class="p-operate">
                          <number-box :defaultVal="carts[index].addNum" v-model="carts[index].addNum" @on-change="onParamChange(index, $event)" :max="99999" :min="0" :step="2" @keyup.enter.native="addCart(index,good)"></number-box>
                          <el-badge :value="carts[index].num" class="item" v-if="carts[index].num>0">
                            <a href="javascript:void(0);" v-on:click="addCart(index,good)" class="addcart">
                              <i class="iconfont icon-carts"></i>加入购物车
                            </a>
                          </el-badge>
                          <a href="javascript:void(0);" v-on:click="addCart(index,good)" class="addcart" v-if="carts[index].num<=0">
                            <i class="iconfont icon-carts"></i>加入购物车
                          </a>
                        </div>
                      </div>
                      <div class="brief" v-if="good.subName">
                        [促]
                        <p v-for='(b,i) in good.subName.split("$$")' v-bind:key="i">
                          <template v-if="b.indexOf('仅台州瑞人堂') == -1 ">{{b}}</template>
<!--                          <template v-else>{{b}}</template>-->
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tc" v-if="paging.list && paging.list.length > 0">
              <div class="pages" id="pager">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="handleCurrentChange"
                    :current-page.sync="pageNow"
                    :page-size="20"
                    :total="totalCount">
                </el-pagination>
              </div>
            </div>

            <div class="no_records" v-if="paging.list && paging.list.length==0">
              <i class="no_icon_two"></i>
              <div class="no_info no_info_line">
                <h3>抱歉，没有找到符合条件的数据</h3>
                <div class="no_btn">
                  <router-link :to="{path:'/'}" class="btn sc-redBg-btn">
                    返回首页
                  </router-link>
                </div>
              </div>
            </div>

            <div class="clear"></div>
          </div>
          <div class="goods-spread">
            <a href="javascript:void(0);" class="g-stop">
              <i class="iconfont icon-right"></i>
            </a>
            <div class="gs-warp">
              <div class="gs-tit">推广商品</div>
              <ul class="gs-list">
                <li class="opacity_img" v-for="(good) in extensions" v-bind:key="good.id">
                  <div class="">
                    <div class="p-img">
                      <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                        <img :src="getCoverImage(good.images)" width="190" height="190">
                      </router-link>
                    </div>
                    <div class="p-price">¥{{good.price}}</div>
                    <div class="p-name">
                      <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                        {{good.name}}
                      </router-link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <guess-love :count="7"></guess-love>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import NumberBox from '@/components/NumberBox/Index'
import AmountBox from '@/components/AmountBox/Index'
import GuessLove from '@/components/GuessLove/Index'
// eslint-disable-next-line no-unused-vars
import {cartCreate, getGoodsSearch} from '@/api/good'
// eslint-disable-next-line no-unused-vars
import {getCartList, setCartNum} from "@/api/user";

export default {
  name: "Category",
  components: {
    Header,
    Footer,
    NumberBox,
    AmountBox,
    GuessLove
  },
  data() {
    return {
      extensions : [],      //推广
      guesses : [],         //猜喜欢
      paging : {},
      carts : [],
      CrumbsCategories: [], //面包屑
      id : 0,
      pageNow : 1,
      totalCount : 0,
      totalPage: 0,
      keywords: '',
      SearchLoading:false,

      priceOrder:'price asc',
      priceIcon: 'icon-arrow-up',
      listType: false,

      cartList: [],
      special:'',
      ids:{
        '三九' : '1030351,1002206,1028781,1032846,1016818,1012183,1003576,1013825,1005695,1001702,1002011,1009584,1001630,1004645,1012567,1020193,1001134,1018925,1014236,1042346,1029934,1002659,1005236,1029201,1004722,1010026,1008649,1001677,1010028,1002524,1029820,1002188,1006146,1006147,1028522,1002972,1016987,1041389,1041296,1013207,1039363,1003280,1028727,1006404,1036698,1041278,1002523,1018363,1012572,1008207,1039175,1003443,1032850,1031681,1031680,1031752,1020925,1005198,1005827,1003281,1004690,1004723,1008388,1006790,1013954',
        '拜耳' : '1014676,1014892,1004505,1002202,1008003,1010021,1031914,1004398,1004670,1004488,1003488,1003103,1000186,1014675,1006254,1005579,1014891,1029943,1014085,1010218,1002755,1007719,1003535,1008357,1006252,1001652,1000984',
        '葵花' : '1037318,1038203,1021034,1010806,1020440,1024201,1018614,1016266,1028517,1001853,1003178,1007180,1004312,1008999,1008956,1031198,1005918,1034713,1034712,1031034,1009070,1007233,1040521,1040520,1031932,1001722,1005358,1003949,1002446,1004718,1015323,1015322,1001414,1006486,1015324,1000853,1035319,1005448,1014496,1032089,1000570,1042402,1016071,1000571,1005074,1011116,1038117,1009179,1000569',
        '思密达' : '1005050,1026420,1028274,1010046',
        '国药致君': '1002615,1010302,1006008,1009614,1014562,1003996,1003812,1017036,1003637,1003468,1003884',
        '和黄' : '1002016,1002303,1002758,1002572,1010792,1009725',
        '济川' : '1011355,1021631,1043858,1018314,1007543,1041297,1007547,1022478,1041785,1033179,1021043,1004893',
        '金活' : '1005647,1006828,1005648,1004580,1005114,1003339,1015967,1015968,1015969,1002250,1015961',
        '万通' : '1031664,1004638,1008745,1003349,1038474,1032060,1009131,1008278,1006253,1008849,1031663,1008597,1001758,1010430,1012934,1004383,1032052,1030919,1002101,1015941,1001998,1031647,1001906',
        '滇虹' : '1000854,1000855,1007156,1004612,1042362,1013787,1011057,1001911,1010101,1033821,1011222,1017624,1003887,1027182,1001560',
        '白云山' : '1001734,1002961,1001745,1002674,1001747,1001746,1013128,1002021,1013120,1028858,1002080,1000482,1014372,1042264,1007569,1022069,1002108,1001735,1019769,1002204,1002679,1007769,1007749',
        '韩美' : '1038839,1008883,1002735,1044557,1041392,1045443,1004503,1002110,1006832,1009136,1002548,1010047,1002547,1009677,1001389,1044607,1045413,1010588'
      }

    }
  },
  watch: {
    $route: {
      handler: function(){
        this.keywords = this.$route.query.keywords
        this.special = this.$route.query.special

        this.getGoods()
      }
    },
    pageNow:{
      handler: function(){
        this.getGoods()
      }
    }
  },
  created() {

    this.keywords = this.$route.query.keywords
    this.special = this.$route.query.special

    this.getExtensions();
    this.getGuesses();
    this.getGoods();
  },
  mounted() {
    this.$refs.header.hideCate();

  },
  computed:{
  },
  methods:{
    getExtensions(){
      // getGoodsRecommendList({
      //   id:6
      // }).then(res => {
      //   this.extensions = res.data
      //   this.extensions.forEach((i)=>{
      //     if(i.image.indexOf("image.yabyy.com") == -1){
      //       i.image = settings.AdminUrl + i.image
      //     }
      //   })
      // })
      getGoodsSearch({
        currentPage: 1,
        pageSize: 6,
        sidx: 'newid()',
      }).then(res => {
        this.extensions = res.data.list
      });
    },
    getGuesses(){
      // getGoodsRecommendList({
      //   id:7
      // }).then(res => {
      //   this.guesses = res.data
      //   this.guesses.forEach((i)=>{
      //     if(i.image.indexOf("image.yabyy.com") == -1){
      //       i.image = settings.AdminUrl + i.image
      //     }
      //   })
      // })
      getGoodsSearch({
        currentPage: 1,
        pageSize: this.count,
        sidx: 'newid()',
      }).then(res => {
        this.guesses = res.data.list;
      })
    },
    getGoods(){
      // this.carts = []
      // this.cartList = []
      //
      // this.SearchLoading = true
      // let where ={}
      // where.searchName = this.$route.query.keywords
      // getGoodsPageList({
      //   page:this.pageNow,
      //   limit:20,
      //   order:this.priceOrder,
      //   where:JSON.stringify(where)
      // }).then(res => {
      //
      //   let _this = this
      //   this.paging = res.data
      //   this.totalCount = res.data.totalCount
      //   this.totalPage = res.data.totalPages
      //
      //   var productIds = res.data.list.map((item)=>{
      //     return item.productId;
      //   })
      //   //获取当页购物车信息，如果获取全部代价有点大（比如有些客户购物出列表300+）
      //   getCartList({ids:productIds.join(',')}).then(res=>{
      //     this.cartList = res.data.list
      //
      //     this.paging.list.forEach((i)=>{
      //
      //       if(i.image.indexOf("image.yabyy.com") == -1){
      //         i.image = settings.AdminUrl + i.image
      //       }
      //       if(i.parameters!=null){
      //         i.params = i.parameters.split('|')
      //       }
      //
      //       let has = _this.cartList.filter(item=>item.productId==i.productId)
      //       if(has.length>0){
      //         i.num = has[0].nums
      //         i.addNum = 0
      //         this.carts.push({num: has[0].nums,addNum:0});
      //       }else{
      //         i.num = 0
      //         i.addNum = 0
      //         this.carts.push({num: 0,addNum:0});
      //       }
      //     })
      //   })
      // }).finally(()=>{
      //   this.SearchLoading = false
      // })

      let ids = ''
      if(this.special){
        ids = this.ids[this.special]
      }
      if(ids == undefined){
        ids = ''
      }

      this.carts = []
      this.cartList = []
      this.SearchLoading = true
      getGoodsSearch({
        currentPage: this.pageNow,
        pageSize: 20,
        sidx: this.sidx,
        sord: this.sord,
        ids: ids,
        search: this.$route.query.keywords
      }).then(res => {
        this.paging = res.data
        this.totalCount = res.data.pagination.total
        this.totalPage = res.data.pagination.total

        const goodsIds = res.data.list.map((item) => {
          return item.id;
        });

        // this.totalCount = res.data.totalCount
        // this.totalPage = res.data.totalPages
        //
        // var productIds = res.data.list.map((item)=>{
        //   return item.productId;
        // })
        //获取当页购物车信息，如果获取全部代价有点大（比如有些客户购物出列表300+）
        getCartList({goodsIds:goodsIds.join(',')}).then(res=>{
          this.cartList = res.data.normal
          this.paging.list.forEach((i)=>{
            // if(i.image.indexOf("image.yabyy.com") == -1){
            //   i.image = settings.AdminUrl + i.image
            // }

            if(i.paramsData != null){
              i.paras = JSON.parse(i.paramsData.replace(/[\r|\n|\t]/g,''));
            }else{
              i.paras = []
            }

            let has = this.cartList.filter(item=>item.goodsId == i.goodsId)
            if(has.length>0){
              i.num = has[0].num
              i.addNum = 0
              this.carts.push({num: has[0].num,addNum:0});
            }else{
              i.num = 0
              i.addNum = 0
              this.carts.push({num: 0,addNum:0});
            }
          })
        })
      }).finally(()=>{
        this.SearchLoading = false
      })

    },
    onParamChange(index, val){
      this.carts[index].addNum = val
    },
    addCart(index,goods){
      if(this.carts[index].addNum <= 0){
        this.$message({
          message: '购物车数量必须大于0',
          type: 'warning'
        });
      }else{
        let _this = this

        cartCreate({
          goodsId: goods.id,
          goodsNo: goods.no,
          qty: this.carts[index].addNum,
          price: goods.price,
          signId: 2,
          //skuId: this.showSelect.Id,
          specInfo : goods.specs
        }).then((res) => {
          if(res!=undefined){
            _this.$message({
              message: '加入购物车成功',
              type: 'success'
            });

            _this.carts[index].num = this.carts[index].num + this.carts[index].addNum;
            _this.carts[index].addNum = 0;

            _this.$store.dispatch('GetCartSum');
          }
        }).catch(err => {
          console.log(err);
          // _this.$h.toast(err);
          // _this.$toError(err);
        });


        // addCart({
        //   productId: productId,
        //   nums: this.carts[index].addNum
        // }).then(res => {
        //   if(res!=undefined){
        //     this.$message({
        //       message: res.msg,
        //       type: 'success'
        //     });
        //
        //     this.carts[index].num = this.carts[index].num + this.carts[index].addNum
        //     this.carts[index].addNum = 0
        //
        //     this.$store.dispatch('GetCartSum')
        //   }
        // })

      }

    },
    handleCurrentChange(val) {
      this.pageNow = val
    },
    handlePreChange() {
      if(this.pageNow-1<=0)
        this.pageNow = 1
      else
        this.pageNow = this.pageNow -1
    },
    handleNextChange() {
      if(this.pageNow + 1 >= this.totalPage)
        this.pageNow = this.totalPage
      else
        this.pageNow = this.pageNow + 1
    },
    orderPrice(){
      if(this.priceOrder=='price asc'){
        this.priceOrder = 'price desc'
        this.sord = 'desc'
        this.sidx = 'Price'
        this.priceIcon = 'icon-arrow-down'
        this.getGoods()
      }else{
        this.priceOrder = 'price asc'
        this.sord = 'asc'
        this.sidx = 'Price'
        this.priceIcon = 'icon-arrow-up'
        this.getGoods()
      }
    },
    getCoverImage(images){
      let json = JSON.parse(images)
      return json[0].attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + json[0].imageUrl
    },
  }
}
</script>

<style>
@import "../assets/style/select.css";

.p-name .el-button{
  padding: 0;
}

.gl-h-item .gl-i-wrap .col-1{ width:20%; margin-right:1%;}
.gl-h-item .gl-i-wrap .col-2{ width:42%; margin-right:1%;}
.gl-h-item .gl-i-wrap .col-3{ width:8%; margin-right:1%;}
.gl-h-item .gl-i-wrap .col-4{ width:27%; margin-right:0;}
.col .p-lie{ margin-top:0;}
.gl-i-wrap .p-name {margin-top: 0;}

@media screen and (min-width: 1230px) and (max-width: 1450px){
  .gl-h-item .gl-i-wrap .col-1{ width:20% !important; margin-right:1% !important;}
  .gl-h-item .gl-i-wrap .col-2{ width:41% !important;; margin-right:1% !important;;}
  .gl-h-item .gl-i-wrap .col-3{ width:9% !important;; margin-right:1% !important;;}
  .gl-h-item .gl-i-wrap .col-4{ width:27% !important;; margin-right:0 !important;;}
}

.p-num,.p-name{
  color: #000 !important;
  font-size: 13px;
}
.p-num{
  display: block !important;
}

.col-2 ul li{
  width: 49%;
  float: left;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
}

.col .p-name{
  height: 60px;
}
.col .p-name a{
  height: 60px;
  white-space: break-spaces;
}

.counter-component{float: left;padding-right: 5px;}
.counter-component input{width: 58px;}
.counter-show input{
  border-top: 1px dotted #dbdbdb !important;
  border-bottom: 1px dotted #dbdbdb !important;
}
.counter-btn {
  border: 1px dotted #dbdbdb !important;
}

.gl-i-wrap .p-operate{overflow: visible;}
.gl-i-wrap .p-operate {padding-right: 30px;}
.el-badge__content.is-fixed{z-index: 9999;}

.el-pagination{
  font-weight: normal;
}
.el-pager .number:hover{
  color: #f42424 !important;
}
.el-pager .active{
  background-color: #f42424 !important;
  color: #fff;
}
.el-pager .active:hover{
  color: #fff !important;
}

.brief{clear: both;color: blue;font-weight: bold;}

.original-price{text-decoration:none}
.gl-warp-large .original-price{padding-left: 48px;font-size: 16px;}

.p-name{
  font-size: 14px;
  font-weight: bold;
}

.tip{
  background-color: #e6a23c;
  padding: 12px 5px 12px;
  color: #fff;
  margin-bottom: 8px;
}
</style>
